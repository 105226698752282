main {
  flex: 1;
}

main ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  list-style: none;
}

main ul .dev-item {
  border: 0px solid #1c6ea4;
}

@media (max-width: 650px) {
  main ul {
    grid-template-columns: 1fr;
  }
}
