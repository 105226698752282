@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  background: #e5e6f0;
  -webkit-font-smoothing: antialiased;
}

body,
input,
button {
  font-family: Roboto, sans-serif;
}

#app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 30px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

#app main {
  margin-left: 30px;
}

#app .img-box {
  display: grid;
  justify-items: center;
  margin-bottom: 10px;
}

#app img {
  max-width: 50px;
  max-height: 50px;
}

@media (max-width: 1000px) {
  #app {
    flex-direction: column;
  }

  #app main {
    margin-left: 0;
    margin-top: 30px;
  }

  #app aside {
    width: 100%;
  }
}

aside {
  width: 320px;
  background: #ffffff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.02);
  border-radius: 2px;
  padding: 30px 20px;
}

aside strong {
  font-size: 20px;
  text-align: center;
  display: block;
  color: #333;
}

aside form {
  margin-top: 30px;
}

aside form .input-block + .input-block {
  margin-top: 20px;
}

aside form .input-group {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

aside form .input-group .input-block {
  margin-top: 0;
}

aside form .input-block label {
  color: #acacac;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

aside form .input-block input {
  width: 100%;
  height: 32px;
  font-size: 14px;
  color: #666;
  border: 0;
  border-bottom: 1px solid #eee;
}

aside form button[type='submit'] {
  width: 100%;
  border: 0;
  margin-top: 30px;
  background: #7d40e7;
  border-radius: 2px;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.5s;
}

aside form button[type='submit']:hover {
  background: #6931ca;
}

main {
  flex: 1 1;
}

main ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
  list-style: none;
}

main ul .dev-item {
  border: 0px solid #1c6ea4;
}

@media (max-width: 650px) {
  main ul {
    grid-template-columns: 1fr;
  }
}

svg g#halfSeen {
  opacity: 0.3;
}

svg.icon {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  opacity: 0.4;
  transition: all 0.25s;
}

svg.icon.clickable {
  cursor: pointer;
}

svg.icon#trash,
svg.icon#pen {
  fill: #8e4dff;
  opacity: 0.7;
}

svg.icon#trash:hover {
  fill: #5a2ea6;
  opacity: 1;
}

svg.icon#pen:hover {
  fill: #5a2ea6;
  opacity: 1;
}

li.dev-item {
  background: #ffffff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.02);
  border-radius: 2px;
  padding: 20px;
}

li.dev-item header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

li.dev-item header img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

li.dev-item header .user-info {
  margin-left: 10px;
}

li.dev-item header .icons {
  float: right;
  margin-left: auto;
}

.user-info strong {
  display: block;
  font-size: 16px;
  color: #333;
}

.user-info span {
  font-size: 13px;
  color: #999;
  margin-top: 2px;
}

li.dev-item p {
  color: #666;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}

li.dev-item a {
  color: #8e4dff;
  font-size: 14px;
  text-decoration: none;
}

li.dev-item a:hover {
  color: #5a2ea6;
}

li.dev-item .icons {
  position: relative;
}

li.dev-item #trash {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -8px;
  top: -9px;
  font-size: 25px;
}

li.dev-item #pen {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -8px;
  top: -9px;
  font-size: 25px;
  margin-top: 50px;
}

aside form {
  margin-top: 30px;
}

aside form .input-block + .input-block {
  margin-top: 20px;
}

aside form .input-group {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

aside form .input-group .input-block {
  margin-top: 0;
}

aside form .input-block label {
  color: #acacac;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

aside form .input-block input,
aside form .input-block textarea {
  width: 100%;
  height: 32px;
  font-size: 14px;
  color: #666;
  border: 0;
  border-bottom: 1px solid #eee;
}

aside form .input-block textarea {
  height: 70px;
  resize: none;
}

aside form button[type='submit'] {
  width: 100%;
  border: 0;
  margin-top: 30px;
  background: #7d40e7;
  border-radius: 2px;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: background 0.5s;
}

aside form button[type='submit']:hover {
  background-color: #6931ca;
}

:not(output):-moz-ui-invalid {
  box-shadow: 0 0 1.5px 1px red;
}

